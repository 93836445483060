<template>
    <div class="content">
        <div v-loading="loading" class="content-item content-item-0">
            <div class="item-title">
                <span class="title">学生统计</span>
            </div>
            <div v-if="statisticsData.haveClassAuth" class="item">
                <statistics-box
                    title="班级"
                    :count="statisticsData.viewClassCount"
                    unit="个"
                    @click.native="clickStuStatistics"
                />
                <statistics-box
                    title="人数"
                    :count="statisticsData.studentCount"
                    unit="人"
                    @click.native="clickStuStatistics"
                />
            </div>
            <div v-else-if="!loading" class="tips">
                无学生班级权限
            </div>
        </div>

        <div v-loading="loading" class="content-item content-item-1">
            <div class="item-title">
                <span class="title">本月出勤统计</span>
                <span class="more" @click="clickAttendance">
                    <span>查看部门</span>
                    <i class="el-icon-arrow-right"></i>
                </span>
            </div>
            <div v-if="statisticsData.isAttendanceRule" class="item">
                <statistics-box
                    title="出勤天数"
                    :count="statisticsData.attendanceDays"
                    unit="天"
                />
                <statistics-box
                    title="休息"
                    :count="statisticsData.restDays"
                    unit="天"
                />
                <statistics-box
                    title="缺卡"
                    :count="statisticsData.noSignCount"
                    unit="次"
                />
                <statistics-box
                    title="迟到"
                    :count="statisticsData.lateCount"
                    unit="次"
                />
                <statistics-box
                    title="早退"
                    :count="statisticsData.earlyCount"
                    unit="次"
                />
            </div>
            <div v-else-if="!loading" class="tips">
                未加入考勤组
            </div>
        </div>

        <div v-loading="loading" class="content-item content-item-2">
            <div class="item-title">
                <span class="title">课程统计</span>
            </div>
            <div class="item">
                <statistics-box
                    title="课程"
                    :count="statisticsData.courseCount"
                    unit="课时"
                    @click.native="clickCourse"
                />
            </div>
        </div>
    </div>
</template>
<script>
import StatisticsBox from "./StatisticsBox.vue";

export default {
    name: 'Statistics',
    components: {
        StatisticsBox
    },
    data() {
        return {
            loading: true,
            statisticsData: {
                haveClassAuth: 1, // 是否有班级权限  0否 1是
                viewClassCount: "-", // 班级个数
                studentCount: "-", // 学生人数
                isAttendanceRule: 1, // 是否有考勤组 0否1是
                attendanceDays: "-", // 出勤天数
                restDays: "-", //  休息天数
                noSignCount: "-", // 缺卡
                lateCount: "-", // 迟到
                earlyCount: "-", // 早退
                courseCount: "-", // 课时
            },
        }
    },
    mounted() {
        if (this.$store.state.isSuper !== '1') {
            this.getSchoolCount();
        } else {
            this.loading = false
        }
    },
    methods: {
        getSchoolCount() {
            this._fet('/school/schoolTeacher/getWorkPlatformTeacherInfo').then((res) => {
                if (res.data.code === '200') {
                    this.statisticsData = Object.assign({}, res.data.data)
                    if (this.statisticsData.restDays === '') {
                        this.statisticsData.restDays = 0
                    }
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                    this.statisticsData.viewClassCount = 0
                    this.statisticsData.studentCount = 0
                    this.statisticsData.attendanceDays = 0
                    this.statisticsData.restDays = 0
                    this.statisticsData.noSignCount = 0
                    this.statisticsData.lateCount = 0
                    this.statisticsData.earlyCount = 0
                    this.statisticsData.courseCount  = 0
                }
            }).finally(() => {
                this.loading = false
            })
        },
        // 跳转到学生档案
        clickStuStatistics() {
            const flag = this.hasRouterPerm('SummaryStudent')
            if (flag) {
                this.$router.push({
                    name: 'SummaryStudent'
                })
            } else {
                this.$message.warning('您无权查看学生档案信息')
            }
        },
        // 跳转到考勤统计
        clickAttendance() {
            const flag = this.hasBtnPerm('checkattendance:analysis')
            if (flag) {
                this.$router.push({
                    name: 'SManageTeacherAttendanceRecord',
                    query: {
                        id: 4
                    }
                })
            } else {
                this.$message.warning('您无权查看部门考勤统计')
            }
        },
        // 跳转到我的课表
        clickCourse() {
            this.$eventDispatch('isShowMyCurriculum', true)
        },
        hasRouterPerm(perm) {
            return this.$store.state.originRouters.findIndex(i => i.name === perm) > -1
        },
        hasBtnPerm(prem) {
            return this.$store.state.permissions.includes(prem)
        }
    }
}
</script>
<style lang="scss" scoped>
.content {
    display: flex;
    justify-content: center;
    align-content: center;

    .content-item-0 {
        min-width: 290px;
        background: linear-gradient(180deg, #F8ECFF 0%, #FDF8FE 35%, #FFFFFF 100%);

        .statistics-box {
            cursor: pointer;
            width: 144px;
        }
    }

    .content-item-1 {
        min-width: 715px;
        background: linear-gradient(180deg, #EFFFDE 0%, #FCFFF9 42%, #FFFFFF 100%);
    }

    .content-item-2 {
        background: linear-gradient(180deg, #C2E7FF 0%, #E1F3FF 35%, #FFFFFF 100%);

        .statistics-box {
            cursor: pointer;
            width: 140px;
        }
    }

    .content-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 1px 1px 3px 0 #B0CDFF;
        border-radius: 10px;
        border: 2px solid #FFFFFF;
        height: 112px;
        box-sizing: border-box;
        padding: 3px 0 16px;

        +.content-item {
            margin-left: 10px;
        }

        .item-title {
            font-family: Source Han Sans CN;
            text-align: center;
            position: relative;

            .title {
                font-weight: bold;
                font-size: 16px;
                color: #333333;
            }

            .more {
                cursor: pointer;
                position: absolute;
                right: 10px;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
            }
        }

        .item {
            display: flex;
            justify-content: center;
        }

        .tips {
            color: #666666;
            font-size: 14px;
            line-height: 55px;
            text-align: center;
        }
    }
}
</style>
