<!-- 首页-个人信息 -->
<template>
    <hm-card
        v-loading="loading"
        :height="210"
        class="home-statistics"
    >
        <div class="statistics-wrap">
            <div class="statistics-wrap-top">
                <div class="content-info">
                    <div class="userName">
                        <img
                            :src="userImg ? decodeURI(userImg) : imgErrorAdr"
                            alt=""
                        />
                        <div class="userName_text">
                            <div class="jobName">
                                <div class="name">{{ $store.state.userName || "admin" }}</div>
                                <div class="tip">认认真真工作，踏踏实实做人</div>
                            </div>
                            <div class="text">班主任</div>
                        </div>
                    </div>
                    <div class="item">
                        <img
                            v-if="weatherIcon"
                            :src="weatherIcon"
                            alt=""
                            class="item-weather"
                        />
                        <div class="item-center">{{ dayweather }}</div>
                        <div v-if="nighttemp && daytemp">{{ nighttemp }}°C~{{ daytemp }}°C</div>
                    </div>
                </div>
            </div>
            <div class="statistics-wrap-bottom">
                <Statistics></Statistics>
            </div>
        </div>
    </hm-card>
</template>

<script>
import {mapState} from "vuex";
import HmCard from "./Sub/hm-card.vue";
import CourseSchedule from "./MyCurriculum/CourseSchedule.vue";
import Statistics from "./HomeStatistics/Statistics.vue";
import {TeachingResearchCourseScheduleTempModel} from "@/models/TeachingResearchCourseScheduleTemp.js";

import {
    // 功能组件
    DialogWrapper,
    // 工具函数
    debounce,
} from "common-local";
export default {
    name: "HomeStatistics",
    components: {
        HmCard,
        DialogWrapper,
        CourseSchedule,
        Statistics
    },
    data() {
        return {
            loading: false,
            schoolIntroduce: "",
            // week: "",
            // date: "",
            // nowTime: "",
            address: "",
            dayweather: "",
            daytemp: "",
            nighttemp: "",
            dayWindy: "",
            weatherIcon: "",
            dialogMyCurriculum: {
                title: "我的课程",
                dialogVisible: false,
                width: "1200px",
            },
            isShowMyCurriculum: false,
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            userImg: (state) => state.userImg,
        }),
        addressIcon() {
            return require("@/assets/images/home/address.png");
        },
        calendar() {
            return require("@/assets/images/home/calendarIcon.png");
        },
        imgErrorAdr() {
            return require("@/assets/images/imgErrorAdr.png");
        },
    },
    created() {
        this.getWeather();
        // this.updateTime();
    },
    methods: {
        closeMyCurriculum() {
            this.dialogMyCurriculum.dialogVisible = false;
        },
        /**
         * @Description: 获取天气
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-08-26 15:31:22
         */
        getWeather() {
            var xhr = new XMLHttpRequest();
            xhr.open("get", `https://query.asilu.com/weather/gaode`);
            xhr.send(null);
            xhr.onreadystatechange = () => {
                try {
                    var res = JSON.parse(xhr.response);
                    let {forecasts} = res;
                    let {city, reporttime, casts} = forecasts[0];
                    let {
                        daytemp,
                        dayweather,
                        daywind,
                        nighttemp,
                        nightweather,
                        nightwind,
                        week,
                    } = casts[0];
                    let arr = ["一", "二", "三", "四", "五", "六", "日"];

                    this.week = "周" + arr[Number(week) - 1];
                    this.date = reporttime.slice(0, 10);
                    this.address = city;
                    // 获取当前时间
                    let now = new Date();
                    // 获取当前小时数
                    let hour = now.getHours();
                    // 判断当前时间是早上、中午还是下午、晚上
                    if (hour >= 5 && hour < 18) {
                        this.dayweather = dayweather;
                        this.dayWindy = daywind;
                    } else {
                        this.dayweather = nightweather;
                        this.dayWindy = nightwind;
                    }
                    this.daytemp = daytemp;
                    this.nighttemp = nighttemp;
                    this.loading = false;
                    this.weatherIcon = this.getWeatherIcon(this.dayweather);
                    console.log(this.weatherIcon, "天气图标");
                } catch (err) {
                    console.log(err);
                }
            };
        },
        /**
         * @Description: 实时获取当前时分秒
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-08-26 15:47:28
         */
        // updateTime() {
        //     setInterval(() => {
        //         let now = new Date();
        //         let hour =
        //             now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
        //         let minute =
        //             now.getMinutes() < 10
        //                 ? "0" + now.getMinutes()
        //                 : now.getMinutes();
        //         let second = now.getSeconds().toString().padStart(2, "0");
        //         this.nowTime = `${hour}:${minute}:${second}`;
        //     }, 1000);
        // },
        /**
         * @Description: 我的日常跳转到对应页面
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-08-28 09:25:56
         */
        handleGoPath(path) {
            switch (path) {
                case "SEduLessonSubjectPlan":
                    this.$router.push({
                        path: "/teachingResearchCourseScheduleTemp",
                    });
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 判断天气图标
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-08-28 10:24:04
         */
        getWeatherIcon(weather) {
            if (weather.includes("雷")) {
                return require("@/assets/images/weather/thunderstorm.png");
            } else if (weather.includes("晴")) {
                return require("@/assets/images/weather/sunny.png");
            } else if (weather.includes("多云") || weather.includes("阴")) {
                return require("@/assets/images/weather/cloudy.png");
            } else if (weather.includes("雪")) {
                return require("@/assets/images/weather/snow.png");
            } else if (weather.includes("雨")) {
                return require("@/assets/images/weather/rain.png");
            } else {
                return "";
            }
        },

        /**
         * @Description: 点击打开我的课程表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-10-18 10:32:49
         */
        openMyCurriculum() {
            this.$emit("isShowMyCurriculum", true);
            // this.isShowMyCurriculum = true;
        },
        /**
         * @Description: 关闭弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-10-18 10:39:01
         */
        handleCloseMyCurriculum() {
            this.dialogMyCurriculum.dialogVisible = false;
        },
    },
};
</script>

<style scoped lang="scss">
.hm-card {
    padding: 0;
}
.statistics-wrap {
    // width: 465px;
    height: 100%;
    background: url(~@/assets/images/home/worktopStatistics.png) no-repeat;
    background-size: 100% 100%;
    .statistics-wrap-top {
        width: 100%;
        // background: url(~@/assets/images/home/personalInforIcon.png) no-repeat
        //     #ffffff;
        // background-size: 100% 101%;
        box-sizing: border-box;
        padding: 15px 29px 13px 21px;
        .content-info {
            display: flex;
            justify-content: space-between;

            .userName {
                display: flex;
                img {
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    margin-right: 13px;
                }
                .userName_text {
                    font-family: Microsoft YaHei;
                    color: #333333;
                    .jobName {
                        display: flex;
                        align-items: center;

                        .name {
                            font-weight: bold;
                            font-size: 20px;
                            margin-right: 13px;
                        }
                        .tip {
                            font-weight: 400;
                            font-size: 18px;
                        }
                    }
                    .text {
                        font-weight: 400;
                        font-size: 14px;
                    }
                }
            }
            .item {
                display: flex;
                align-items: center;
            }
            .item-weather {
                width: 25px;
                height: 25px;
            }
            .item-center {
                margin: 0 10px;
            }

        }
        .my_curriculum {
            margin-top: 30px;
        }
    }
    .statistics-wrap-bottom {
        width: 100%;
        height: 122px;
        box-sizing: border-box;
    }
}
</style>
