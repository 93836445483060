<template>
    <div class="statistics-box">
        <div class="title">{{ title }}</div>
        <div class="content">
            <span class="count" :class="{'is-danger': title === '迟到'}">
                {{ count }}
                <span class="unit">{{ unit }}</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ""
        },
        count: {
            type: [Number, String],
            default: 0
        },
        unit: {
            type: String,
            default: ""
        },
    }
}
</script>

<style scoped lang="scss">
.statistics-box {
    position: relative;
    min-height: 55px;
    min-width: 130px;

    .title {
        font-size: 14px;
        color: #666666;
        margin-left: 21px;
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 38px;

        .count {
            position: relative;
            line-height: 1.1;
            font-weight: bold;
            font-size: 30px;
            color: #000000;

            &.is-danger {
                color: #FF5363;
            }
        }

        .unit {
            position: absolute;
            bottom: 3px;
            right: 0;
            transform: translateX(100%);
            padding-left: 6px;
            font-weight: normal;
            font-size: 12px;
            color: #999999;
            white-space: nowrap;
        }
    }

    +.statistics-box:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1px;
        height: 42px;
        background: #E2E2E2;
        border-radius: 1px;
    }
}
</style>